import Pair from './Pair';
import { shuffle } from './Helpers';

class PairOptions {
  pair: Pair;
  options: Array<number>;

  constructor(pair: Pair) {
    this.pair = pair;
    this.options = this.generateOptions();
  }

  // Right now, we only return 4 options,
  // (including the correct answer), and we shuffle them.
  generateOptions() {
    const correct = this.pair.product();
    const productMinusFirst = correct - this.pair.first;
    const productPlusSecond = correct + this.pair.second;
    const smallOffset = Math.floor(Math.random() * 5) + 1;
    const offBySmallNumber = correct - smallOffset;

    const options = [
      productMinusFirst,
      productPlusSecond,
      offBySmallNumber,
      correct
    ]

    return shuffle(options);
  }
};

export default PairOptions;