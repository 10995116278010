import React from 'react';
import Pair from './Pair';
import PairOptions from './PairOptions';
import FlashcardSquare from './FlashcardSquare';
import './Flashcard.css';

interface FlashcardProps {
  pair: Pair;
  pairOptions: PairOptions;
  onDone: () => void;
}

interface FlashcardState {
  selected: number | null;
}

class Flashcard extends React.Component<FlashcardProps, FlashcardState> {
  constructor(props: any) {
    super(props);
    this.state = { selected: null  }
  }

  renderQuestion(pair: Pair) {
    return (
      <div className='flashcard-question'>
        {pair.first} x {pair.second}?
      </div>
    )
  }

  correct(option: number) {
    const { pair } = this.props;
    if(option === pair.product()) {
      return true;
    } else {
      return false;
    }
  }

  onChoose (option: number) {
    this.setState({ selected: option });

    if(this.correct(option)) {
      setTimeout(this.props.onDone, 900);
    }
  }

  render() {
    const { pair, pairOptions } = this.props;
    const options = pairOptions.options;

    const option1 = options[0];
    const option2 = options[1];
    const option3 = options[2];
    const option4 = options[3];

    return(
      <div className='card-container'>
        { this.renderQuestion(pair) }
        <div className='flashcard-row-container'>
          <FlashcardSquare
            isSelected={this.state.selected === option1}
            isCorrect={this.correct(option1)}
            value={option1}
            onClick={ this.onChoose.bind(this, option1)}
          />
          <FlashcardSquare
            isSelected={this.state.selected === option2}
            isCorrect={this.correct(option2)}
            value={option2}
            onClick={ this.onChoose.bind(this, option2)}
          />
        </div>
        <div className='flashcard-row-container'>
          <FlashcardSquare
            isSelected={this.state.selected === option3}
            isCorrect={this.correct(option3)}
            value={option3}
            onClick={ this.onChoose.bind(this, option3)}
          />
          <FlashcardSquare
            isSelected={this.state.selected === option4}
            isCorrect={this.correct(option4)}
            value={option4}
            onClick={this.onChoose.bind(this, option4)}
          />
        </div>
      </div>
    );
  }
}


export default Flashcard;