import React from 'react';
import './FlashcardSquare.css';

interface FlashcardSquareProps {
  isCorrect: boolean;
  isSelected: boolean;
  value: number;
  onClick: () => void;
}

class FlashcardSquare extends React.Component<FlashcardSquareProps, {}> {
  className() {
    let className = '';
    const { isCorrect, isSelected } = this.props;

    if(isCorrect && isSelected) {
      className = 'selected-and-correct';
    } else if (isSelected) {
      className = 'selected-and-wrong';
    } else {
      className = 'not-selected';
    }
    return className;
  }

  render() {
    const { onClick, value } = this.props;

    return (
      <div
        onClick={onClick}
        className={this.className()}
      >
        {value}
      </div>
    )
  }
};

export default FlashcardSquare;