import React from 'react';
import Flashcard from './Flashcard';
import Pair from './Pair';
import PairOptions from './PairOptions';
import './Board.css';
import { shuffle } from './Helpers';

interface BoardState {
  pairIndex: number;
  pairs: Array<Pair>;
}

class Board extends React.Component<{}, BoardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      // These are the pairs that Felicity found challenging.
      // I'll build these out to be randomized and continuous
      // (e.g. I don't think you should be able to win!)
      pairs: shuffle([
        new Pair([4, 7]),
        new Pair([8, 7]),
        new Pair([3,4]),
        new Pair([8,8]),
        new Pair([6,7]),
        new Pair([11,7]),
        new Pair([3,11]),
        new Pair([5,8]),
        new Pair([9,4]),
        new Pair([3,6]),
        new Pair([7,5]),
        new Pair([9,8]),
        new Pair([6,10]),
        new Pair([11,8]),
        new Pair([4,6]),
        new Pair([7,9]),
        new Pair([9,10]),
        new Pair([6,3]),
        new Pair([11,5]),
        new Pair([10,9]),
        new Pair([11,10]),
        new Pair([4,8]),
      ]),
      pairIndex: 0,
    }
  }


  onDone = () => {
    const { pairIndex } = this.state;
    this.setState({pairIndex: pairIndex + 1});
  }

  render() {
    const { pairs, pairIndex } = this.state;
    if(pairIndex < pairs.length) {
      const pair = pairs[pairIndex];
      const pairOptions = new PairOptions(pair);

      return (
        <div>
          <div className='game-title'>Felicity&apos;s Flashcards</div>
          <Flashcard
            onDone={this.onDone}
            pair={pair}
            pairOptions={pairOptions}
          />
        </div>
      );

    } else {
      return (
        <div>
          <div className='game-title'>Felicity&apos;s Flashcards</div>
          <div className='game-closing'>All done!</div>
        </div>
      );
    }
  }
}


export default Board;