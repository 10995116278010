class Pair {
  first: number;
  second: number;

  constructor(arr: Array<number>) {
    this.first = arr[0];
    this.second = arr[1];
  }

  product() {
    return (
      this.first * this.second
    );
  }
};

export default Pair;
